<template>
  <div>
    <!-- 视频 -->
    <el-dialog
      width="50%"
      id="video_upload"
      style="margin-top: 1px"
      title="视频上传"
      :visible.sync="videoUploadTag"
      append-to-body
    >
      <div>
        <el-input
          v-model="videoLink"
          placeholder="请输入视频链接"
          clearable
        ></el-input>
        <el-button
          type="primary"
          size="small"
          style="margin: 20px 0px 0px 0px"
          @click="addVideoLink()"
          >添加
        </el-button>
      </div>
    </el-dialog>
    <!-- 图片上传组件辅助 -->
    <el-upload
      class="avatar-uploader quill-img"
      action="#"
      name="file"
      :show-file-list="false"
      :before-upload="quillImgBefore"
      :http-request="upload"
      accept=".jpg,.jpeg,.png,.gif"
    ></el-upload>

    <!-- 富文本组件 -->
    <quillEditor
      class="editor"
      v-model="content"
      ref="quillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    ></quillEditor>
  </div>
</template>

<script>
// 工具栏配置
const toolbarOptions = (video) => {
  return [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    // ["blockquote", "code-block"], // 引用  代码块
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
    [{ indent: "-1" }, { indent: "+1" }], // 缩进
    [{ size: ["small", false, "large", "huge"] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
    [{ align: [] }], // 对齐方式
    ["clean"], // 清除文本格式
    ["link", "image", video === undefined ? undefined : "video"], // 链接、图片、视频, "video"
  ];
};

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { uploadImage } from "@/api/apiAll/phpUrl.js"; //引入接口
import CImage from "@/utils/compressImage.js";
export default {
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
    },
    video: {},
  },
  components: { quillEditor },
  data() {
    let _this = this;
    return {
      content: this.value,
      videoUploadTag: false,
      videoLink: "",
      editorOption: {
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions(this.video),
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件
                  document.querySelector(".quill-img input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                _this.videoUploadTag = true;
              },
            },
          },
        },
      },
    };
  },

  watch: {
    value: function () {
      this.content = this.value;
    },
  },
  methods: {
    onEditorBlur() {
      //失去焦点事件
    },
    onEditorFocus() {
      //获得焦点事件
    },
    onEditorChange() {
      //内容改变事件
      this.$emit("input", this.content);
    },
    async upload(file) {
      let newFile = await CImage([file.file]);
      let data = new FormData();
      data.append("file", newFile);
      let res = await uploadImage(data);
      this.quillImgSuccess(res.data.full_url);
    },
    // 富文本图片上传前
    quillImgBefore(file) {
      let fileType = file.type;
      if (fileType === "image/jpeg" || fileType === "image/png") {
        return true;
      } else {
        this.$message.error("请插入图片类型文件(jpg/jpeg/png)");
        return false;
      }
    },

    quillImgSuccess(url) {
      // 获取富文本组件实例
      let quill = this.$refs.quillEditor.quill;
      // 获取光标所在位置
      let length = quill.getSelection().index;
      console.log(length);
      // 插入图片 为服务器返回的图片地址
      quill.insertEmbed(length, "image", url);
      // 调整光标到最后
      quill.setSelection(length + 1);
    },

    addVideoLink() {
      if (this.videoLink.length == 0) {
        this.$message({
          type: "info",
          message: "请输入视频地址",
        });
        return;
      }
      // 获取富文本组件实例
      let quill = this.$refs.quillEditor.quill;
      // 获取光标所在位置
      // let reg = quill.getSelection();
      let reg = quill.selection.savedRange;
      let length = (reg && reg.index) || 0;
      //隐藏弹框
      this.videoUploadTag = false;
      // 设置插入视频ID
      let text = `【myVideo:${this.videoLink}END;】`;
      quill.insertText(length, text);
      //将视频链接插入到当前的富文本当中
      // quill.insertEmbed(length, "video", this.videoLink);
      // 调整光标到最后
      quill.setSelection(length + text.length);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .editor {
  line-height: normal !important;
  height: 450px;
}
::v-deep .quill-img {
  display: none;
}
::v-deep .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
::v-deep .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

::v-deep .ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

::v-deep .ql-snow .ql-picker.ql-size .ql-picker-label::before,
::v-deep .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="small"]::before,
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="large"]::before,
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="huge"]::before,
::v-deep
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

::v-deep .ql-snow .ql-picker.ql-font .ql-picker-label::before,
::v-deep .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
::v-deep
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="serif"]::before,
::v-deep
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
::v-deep
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="monospace"]::before,
::v-deep
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
