import Compress from "compress.js";

let compress = new Compress()
const base64ToBlob = function (base64Data, fileName) {
    let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);

    while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
    }

    let blob = new Blob([u8Arr], {
        type: fileType
    });
    return new window.File([blob], fileName, {
        type: fileType
    })
};
export default function (file) {
    return new Promise((reslove, reject) => {
        compress.compress(file, {
            size: 1,
            quality: .75,
            resize: true,
            rotate: false,
        }).then((filebase) => {
            const { prefix, data } = filebase[0]
            const base64 = prefix + data
            reslove(base64ToBlob(base64, file[0].name))
        }).catch(err => {
            reject(err)
        })
    })
}

